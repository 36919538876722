import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { MdHelpOutline, MdHelp } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import { PricesContext } from '../../lib/prices';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import NativeSelect from '@material-ui/core/NativeSelect';
import fetch from 'isomorphic-unfetch';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MdArrowForward } from "react-icons/md";
import Sidebar from '../../components/Sidebar'
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow } from '../../components/CustomAccordion'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query CoinbaseUSDCoinCalculatorPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

// const CalculatorGrid = styled(ContainerMain)`
//   box-sizing: border-box;
//   border: 1px solid ${ props => props.theme.theme.border.secondary};
//   background-color: ${props => props.theme.theme.bg.primary};
//   box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
//   border-radius: 8px;
//   display: grid;
//   grid-template-columns: minmax(300px, 1fr) 3fr;
//   grid-gap: 48px;
//   align-items: start;
//   margin-bottom: 48px;

//   @media (max-width: 500px) {
//     grid-template-columns: 1fr;
//     padding: 24px 24px 0 24px;
//     border: none;
//     border-radius: 0px;
//     margin: 0;
//   }

//   p, ul {
//     font-size: 0.9rem;
//     font-family: 'Inter', sans-serif;
//   }
// `

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 0px;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSelectField = styled(FormControl)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 24px 0;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const InitialInvestmentBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  grid-gap: 16px;
`

const AllocationGroup = styled.div`
  border-radius: 8px;
  padding: 8px 12px 12px 12px;
  margin: 0 0px 24px 0;

  h3 {
    text-transform: uppercase;
    margin: 0 0 8px 0;
    font-weight: 500;
  }
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding-bottom: 12px;
  display: grid;
  /* flex-wrap: wrap; */
  grid-gap: 8px;

  .label {
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 0px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  .equals {
    padding: 0 12px;
  }

  @media (max-width: 500px) {
    /* margin: 0 12px; */
  }
`;

const ResultsGroup = styled.div`
  display: grid;
  align-items: center;
  padding: 4px 0;
  flex-wrap: wrap;
  justify-items: left;

  @media (max-width: 500px) {
  }
`;

const ResultsGroupTwoCol = styled.div`
  display: grid;
`

const InterestTable = styled.div`
  display: grid;
  margin: 0 auto 0 0;
  width: 300px;
  border: 1px solid ${(props) => props.theme.theme.border.secondary};
  padding: 4px;
  background-color: ${props => props.theme.theme.bg.primary};
`

const InterestTableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: left;
  padding: 4px 8px;
  grid-gap: 16px;
  border-top: 1px solid ${(props) => props.theme.theme.border.secondary};

  &.labels-row {
    font-weight: 600;
    border: none;
  }
`

const ActionButton = styled.button`
  background-color: ${(props) => props.theme.theme.colors.blue};
  border: none;
  color: ${(props) => props.theme.theme.colors.white};
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;

  svg {
    padding: 0 0 0 8px;
  }

  &:hover{
    cursor: pointer;
    /* background-color: ${(props) => props.theme.theme.colors.transparentGreenHover}; */
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.1);
  }
`

const InterestRatePanel = styled.div`
  background-color: ${(props) => props.theme.theme.colors.transparentBlue};
  border: 1px solid ${(props) => props.theme.theme.colors.blue};
  color: ${(props) => props.theme.theme.colors.blue};
  /* border: 1px solid ${(props) => props.theme.theme.colors.green}; */
  padding: 10px 12px;
  display: inline-block;
  align-items: center;
  flex-wrap: wrap;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  margin: 12px 0 0 0;
  width: auto;

  svg {
    padding: 0 0 0 8px;
  }
`

const CustomTooltip = (props) => {
  const {active} = props

  if(active){
    const {payload, label } = props
    console.log(payload)
    console.log(label)

    return(
      <TooltipPanel>
        {/* <CardText300>{label}</CardText300> */}
        <CardText400>
          <strong>Month: </strong>
          {payload[0].payload.month}</CardText400>
        <CardText400>
          <strong>Future Value (USDC): </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.localCryptoFutureValue.toFixed(2)}
              thousandSeparator
          />
        </CardText400>

        <CardText400>
          <strong>Monthly interest earned (USDC): </strong>
          <NumberFormat
              displayType={'text'}
              value={payload[0].payload.interestCrypto.toFixed(2)}
              thousandSeparator
          />
        </CardText400>

      </TooltipPanel>
    )
  }
  return(null)

}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // prefix="$"
    />
  );
}

function NumberFormatCrypto(props) {
  const { inputRef, onChange, ticker, ...other } = props;

  console.log(props)

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      suffix={` USDC`}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const USDCoinCalculator = props => {
  const assetOptions = [
    {
      name: 'BTC ( 0 to 1)',
      interest: 6,
      ticker: 'BTC',
      id: 'bitcoin'
    },
    {
      name: 'BTC (> 1.0 to 20)',
      interest: 2,
      ticker: 'BTC',
      id: 'bitcoin'
    },
    {
      name: 'BTC (> 20)',
      interest: 0.5,
      ticker: 'BTC',
      id: 'bitcoin'
    },
    {
      name: 'LINK',
      interest: 5.5,
      ticker: 'LINK',
      id: 'chainlink'
    },
    {
      name: 'ETH (0 to 100)',
      interest: 5.25,
      ticker: 'ETH',
      id: 'ethereum'
    },
    {
      name: 'ETH (>100 to 1000)',
      interest: 2,
      ticker: 'ETH',
      id: 'ethereum'
    },
    {
      name: 'ETH (>1000)',
      interest: 0.5,
      ticker: 'ETH',
      id: 'ethereum'
    },
    {
      name: 'LTC',
      interest: 6.5,
      ticker: 'LTC',
      id: 'litecoin'
    },
    {
      name: 'USDC',
      interest: 8.6,
      ticker: 'USDC',
      id: 'usd-coin'
    },
    {
      name: 'GUSD',
      interest: 8.6,
      ticker: 'GUSD',
      id: 'gemini-dollar'
    },
    {
      name: 'PAX',
      interest: 8.6,
      ticker: 'PAX',
      id: 'paxos-standard'
    },
    {
      name: 'PAXG',
      interest: 5,
      ticker: 'PAXG',
      id: 'pax-gold'
    },
    {
      name: 'USDT',
      interest: 9.3,
      ticker: 'USDT',
      id: 'tether'
    },
    {
      name: 'BUSD',
      interest: 8.6,
      ticker: 'BUSD',
      id: 'binance-usd'
    }
  ]

  const [ timeLength, setTimeLength ] = useState(5)
  const [ principal, setPrincipal ] = useState(10000)
  const [ payment, setPayment ] = useState(0)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(12)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)
  const [ investmentRate, setInvestmentRate ] = useState(4)

  const APR = ( Math.pow( 1 + (investmentRate/100), (1/annualCompoundingPeriods) ) - 1 ) * annualCompoundingPeriods

  const rate = (Math.pow( 1 + (APR/annualCompoundingPeriods), annualCompoundingPeriods/annualPaymentPeriods )) - 1
  const nper = annualPaymentPeriods * timeLength
  const futureValueCrypto = (principal) * Math.pow(1+rate, nper) + payment * ( (Math.pow(1+rate, nper)-1)/ rate )

  const calcCompoundingData = () => {
    let compoundingData = []
    for(let i = 0; i <= timeLength * annualPaymentPeriods; i++){
      const localNper = i
      const localCryptoFutureValue = (principal) * Math.pow(1+rate, localNper) + payment * ( (Math.pow(1+rate, localNper)-1)/ rate )

      compoundingData.push({
        month: i,
        localCryptoFutureValue: localCryptoFutureValue,
        interestCrypto: i ? localCryptoFutureValue - compoundingData[i-1].localCryptoFutureValue - payment : 0
      })
    }
    return compoundingData
  }

  const compoundingData = calcCompoundingData()

  console.log(compoundingData)
  console.log(principal)

  return(
    <>
      <ReactTooltip />
      <ContainerMain>
        <h1>Coinbase USD Coin (USDC) Interest Calculator</h1>
        <H2>How much interest can you earn by depositing your USD Coin in a Coinbase Earn account?</H2>
      </ContainerMain>

      <CalculatorPanel>

      <CalculatorGrid>
        <form>
          <InitialInvestmentBlock>
            <ThemedTextarea
              id="outlined-required"
              label="Initial Investment"
              value={principal}
              onChange={event => setPrincipal(event.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCrypto,
              }}
            />
            <ThemedTextarea
              id="outlined-required"
              label="Interest Rate (APY)"
              value={investmentRate}
              onChange={event => setInvestmentRate(event.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatPercentage
              }}
            />
          </InitialInvestmentBlock>

          {/* <ResultsPanel>
            <ResultsGroup>
              <span className="label">Current {selectedCurrency[0].ticker} price:</span>
              <span className="result-value">
              { selectedAltCurrencyData[0] && <NumberFormat
                  displayType={"text"}
                  value={parseFloat(selectedAltCurrencyData[0][1].usd).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                /> }
              </span>
            </ResultsGroup>
            <ResultsGroup>
              <span className="label">Initial value (USD):</span>
              <span className="result-value">
              { selectedAltCurrencyData[0] && <NumberFormat
                  displayType={"text"}
                  value={(parseFloat(selectedAltCurrencyData[0][1].usd)*principal).toFixed(2)}
                  thousandSeparator
                  prefix="$"
                /> }
              </span>
            </ResultsGroup>
            <ResultsGroup>
              <span className="label">BlockFi interest rate:</span>
              <span className="result-value">
               <NumberFormat
                  displayType={"text"}
                  value={selectedCurrency[0].interest.toFixed(2)}
                  suffix="%"
                  isNumericString
                />
              </span>
            </ResultsGroup>
          </ResultsPanel> */}


          <ThemedTextarea
            id="outlined-required"
            label="Length of time in years"
            value={timeLength}
            onChange={event => setTimeLength(event.target.value)}
            variant="outlined"
            fullWidth
          />

          <ThemedTextarea
              id="outlined-required"
              label="Recurring Monthly Deposit"
              value={payment}
              onChange={event => setPayment(event.target.value)}
              variant="outlined"
              ticker={'btc'}
              suffix={`USDC`}
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCrypto,
                ticker: 'btc'
              }}
            />
          {/* <ThemedTextarea
            id="outlined-name"
            label="Monthly contribution"
            value={payment}
            onChange={event => setPayment(event.target.value)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          /> */}

        {/* <SliderGrid>

          <ThemedSlider
            value={investmentRate}
            aria-labelledby="discrete-slider-always"
            step={0.25}
            min={0}
            max={20}
            marks={[
              {
                value: 0,
                label: '0%'
              },
              {
                value: 7,
                label: '7%'
              },
              {
                value: 20,
                label: '20%'
              }
            ]}
            valueLabelDisplay="auto"
            onChange={ (event, value) => setInvestmentRate(value)}
          />

          <ThemedTextarea
            id="outlined-name"
            label="Rate"
            value={investmentRate}
            onChange={event => setInvestmentRate(event.target.value)}
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatPercentage,
            }}
            fullWidth
          />
        </SliderGrid> */}

          <ResultsPanel>
          <ResultsGroup>
            <span className="label">End value:</span>
            <span className="result-value">
            { futureValueCrypto && <NumberFormat
                displayType={"text"}
                value={futureValueCrypto.toFixed(2)}
                thousandSeparator
                suffix={` USDC`}
              /> }
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Initial monthly earning:</span>
            <span className="result-value">
            <NumberFormat
                displayType={"text"}
                value={(principal*(investmentRate/(100*12))).toFixed(2)}
                thousandSeparator
                suffix={` USDC`}
              />
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Average earning per year:</span>
            <span className="result-value">
            { futureValueCrypto && <NumberFormat
                displayType={"text"}
                value={ ((futureValueCrypto - principal - (payment*timeLength*12))/timeLength).toFixed(2) }
                thousandSeparator
                suffix={` USDC`}
              /> }
            </span>
          </ResultsGroup>

          <ResultsGroup>
            <span className="label">Overall earning:</span>
            <span className="result-value">
            { futureValueCrypto && <NumberFormat
                displayType={"text"}
                value={ (futureValueCrypto - principal - (payment*timeLength*12)).toFixed(2) }
                thousandSeparator
                suffix={` USDC`}
              /> }
            </span>
          </ResultsGroup>
        </ResultsPanel>

          <a href="https://www.coinbase.com/join/549f3ef5e81d8e7f99000004" target="_blank">
            <ActionButton>
              Open a Coinbase Account
              <MdArrowForward size="20px" />
            </ActionButton>
          </a>

          <InterestRatePanel>
            Current Coinbase USDC Interest Rate: 4% APY
          </InterestRatePanel>

          <p>Earn $10 in free bitcoin when you use the link above to open a new Coinbase account and deposit funds.</p>

          {/* <ThemedTextareaPrimary
            id="filled-read-only-input"
            label="Investment Value"
            value={futureValue.toFixed(0)}
            variant="outlined"
            fullWidth
            InputProps={{
              inputComponent: NumberFormatCustom,
              readOnly: true
            }}
          /> */}

        </form>

          <GraphDesktop>
            <ResponsiveContainer width='100%' height={500} >
              <AreaChart data={compoundingData}
                  margin={{top: 30, right: 50, left: 50, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  yAxisId="left"

                />
                <YAxis
                  tickFormatter={tick => {
                    return `${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USDC`
                  }}
                  yAxisId="right"
                  orientation="right"
                />
                {/* <Tooltip/> */}
                <Tooltip
                  content={<CustomTooltip/>}
                />
                <Area type='monotone' name="Investment Value ($)" dataKey='localCryptoFutureValue' stroke='#3AC732' fill='#CFF0CD' yAxisId="left"/>
              </AreaChart>
            </ResponsiveContainer>
          </GraphDesktop>

          <GraphMobile>
            <ResponsiveContainer width='100%' height={500} >
              <AreaChart data={compoundingData}
                  margin={{top: 10, right: 0, left: 0, bottom: 30}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="month" label={{value: "Time (months)", position: "insideBottom", offset: -10}} />
                <YAxis
                  tickFormatter={tick => {
                    return `$${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                  }}
                  yAxisId="left"
                  mirror
                />
                <YAxis
                  tickFormatter={tick => {
                    return `${tick.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} USDC`
                  }}
                  yAxisId="right"
                  orientation="right"
                  mirror
                />
                {/* <Tooltip/> */}
                <Tooltip
                  content={<CustomTooltip/>}
                />
                {/* <Legend verticalAlign="top" height={36} /> */}
                <Area type='monotone' name="Investment Value ($)" dataKey='localCryptoFutureValue' stroke='#3AC732' fill='#CFF0CD' yAxisId="left"/>
              </AreaChart>
            </ResponsiveContainer>
          </GraphMobile>
      </CalculatorGrid>

        <ThemedAccordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>View Monthly Interest Payment Schedule</AccordionSummary>
          <AccordionDetails>
            <FinancesTableWrapper>
              <FinancesTableGrid>
                <FinancesTableRow className="labels-row">
                  <span>Month #</span>
                  <span>Account Value (USDC)</span>
                  <span>Monthly interest earned (USDC)</span>
                </FinancesTableRow>

                {compoundingData.map((dataPoint, index) => {
                  return(
                    <FinancesTableRow>
                        <span>{dataPoint.month}</span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.localCryptoFutureValue.toFixed(2)}
                            thousandSeparator
                          />
                        </span>
                        <span>
                          <NumberFormat
                            displayType={"text"}
                            value={dataPoint.interestCrypto.toFixed(2)}
                            thousandSeparator
                          />
                        </span>
                      </FinancesTableRow>
                  )
                })}
              </FinancesTableGrid>
            </FinancesTableWrapper>
          </AccordionDetails>
        </ThemedAccordion>

      </CalculatorPanel>

      <ContainerTwoCol>
        <ContainerContent>

        <p>
          Remember when you used to be able to earn several percentage points of interest on money parked in your
          savings or checking accounts? With interest rates near all time lows, the days of earning any meaningful
          amount of interest in your traditional savings accounts are long gone.
        </p>
        <p>
          On the bright side, Coinbase, the top US-based crypto exchange, recently announced they will offer a USD Coin (USDC)
          account that earns 4% APY on your deposits which Coinbase then lends out to vetted, verified borrowers. While not FDIC or SIPC
          insured, Coinbase does guarantee your principal, giving you a safe, secure way to earn interest.
        </p>

        <h2>What is USD Coin (USDC)?</h2>
        <p>
          USD Coin (USDC) is a type of cryptocurrency called a stable coin. USDC is issued by <a href="https://www.circle.com/en/usdc" target="_blank">Circle</a> and <a href="https://www.coinbase.com/usdc" target="_blank">Coinbase</a>, two leading
          regulated financial institutions, and is fully backed by reserve assets. The price of 1 USDC is pegged to $1 (US dollar),
          meaning that each USDC is redeemable 1:1 for US Dollars. USDC is widely considered to be the gold standard in USD-pegged
          stable coins and is effectively a digital dollar that allows global transactions denominated in USD between
          crypto-wallets. USD Coin is an Ethereum token powered by the Ethereum network, meaning that it can be stored in any
          Ethereum-based wallet.
        </p>

        <h2>How does it work?</h2>
        <p>
          Once you deposit your USDC at Coinbase, they lend it to verified borrowers and it begins to earn interest.
          There are no fees or withdrawal limits, and your USDC can be sold or sent from your account at any time.
          On Coinbase's website, they claim that your principal is guaranteed by Coinbase, although it is not FDIC or SIPC insured
          like a true savings account at a bank. The fact that Coinbase is an NASDAQ-listed company with a market cap around
          $50 billion, so the fact that they are guaranteeing your principal
          provides the highest level of confidence of any of the crypto-lending platforms.
        </p>

        <h2>About Coinbase</h2>
        <p>
          Coinbase is the largest cryptocurrency exchange in the United States by trading volume. It is ranked as the #3 crypto
          exchange in the world by <a href="https://coinmarketcap.com/rankings/exchanges/" target="_blank">CoinMarketCap.com</a>
          on the basis of traffic, liquidity, trading volumes, and confidence in the legitimacy of trading volumes reported. Coinbase
          is publicly traded on the NASDAQ under the ticker $COIN and has a market cap of around $50 billion at the time of writing.
        </p>

        <h2>Get started with Coinbase</h2>
        <a href="https://www.coinbase.com/join/549f3ef5e81d8e7f99000004" target="_blank">
          <ActionButton>
            Open a Coinbase Account
            <MdArrowForward size="20px" />
          </ActionButton>
        </a>

        <InterestRatePanel>
          Current Coinbase USDC Interest Rate: 4% APY
        </InterestRatePanel>

        <p>
          Signing up for a Coinbase account is easy and can be done in a few minutes.
          Use the sign-up link above to earn $10 in free bitcoin once you create a Coinbase account and deposit funds.
        </p>

        <h2>Coinbase Reputation and Risks</h2>
        <p>
          It's important to do your own research and due diligence before depositing your funds with any financial institution.
          A key thing to understand is that unlike traditional banks, your deposits at Coinbase are not FDIC or SIPC insured like
          a traditional savings account at a bank. However, with the USDC interest earning product, Coinbase claims to privately
          guarantee your principal against loss due to creditors being unable to repay the loan. Combined with the fact that Coinbase
          is the largest crypto institution in the United States, this provides the industry's highest level of confidence in the safety and security
          of your funds.
        </p>

        <h2>Learn more about the Coinbase USDC Interest Account:</h2>
        <ul>
          <li><a href="https://blog.coinbase.com/sign-up-to-earn-4-apy-on-usd-coin-with-coinbase-cdad79e5f5eb" target="_blank">Coinbase Blog Post: Sign up to earn 4% APY on USD Coin with Coinbase</a></li>
        </ul>

        </ContainerContent>

        <Sidebar />
      </ContainerTwoCol>
    </>
  )
}

const CoinbaseCalculatorWrapper = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={'Coinbase USD Coin (USDC) Interest Calculator'}
        description={'Calculate how much interest you can earn on your USD Coin (USDC) cryptocurrency at Coinbase'}
        keywords={site.keywords}
      />
      <USDCoinCalculator />

      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'coinbase-usdc-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default CoinbaseCalculatorWrapper
